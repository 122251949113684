import { IS_SAFARI, VNMESE_CHAR } from 'utils/constants';
import XRegExp from 'xregexp';
import _ from 'lodash';
import { Editor, Node, Transforms } from 'slate';
import { message } from 'antd';

const PUNCTATION = /\.|,|\?|!|。|、|!|@|'|"|_/;

/**
 * Reuse type definations
 * @typedef BaseMark
 * @property {Number} startIndex
 * @property {Number} endIndex
 *
 * @typedef {Object} WordProperties
 * @property {"word"} type
 * @property {Number} start
 * @property {Number} end
 * @property {String} word
 * @property {Number} conf
 *
 * @typedef {Object} SpaceWordProperties
 * @property {String} word
 * @property {"space"} type
 *
 * @typedef {BaseMark & (WordProperties | SpaceWordProperties)} Word
 * @typedef {BaseMark} Highlight
 *
 * @typedef Sentence
 * @property {Word[]} words
 * @property {Highlight[]} highlights
 * @property {String} id
 * @property {Number|String} speaker
 * @property {String} transcript
 * @property {Number} conf
 * @property {Number} start
 * @property {Number} end
 * @property {Boolean} checked
 *
 * @typedef Dict
 * @property {String} dictId
 * @property {String} word1
 * @property {String} word3
 * @property {String} word5
 * @property {Number} sortBy
 */

/**
 * @param {Sentence[]} sentences
 * @param {Dict[]} dicts
 */
export const sentencesToSlateValue = (sentences, dicts, lang = 'vn') =>
  cleanSentences(sentences).map((sentence) => {
    sentence.transcript = sentence.transcript.trim();
    sentence.oldTimestamps = [];
    const { words, transcript } = sentence;
    if (!words || _.isEmpty(words)) {
      sentence.words = [
        {
          conf: 1,
          start: sentence.start,
          end: sentence.end,
          word: sentence.transcript,
        },
      ];
    }
    sentence.words = wordTranscriptMappings(sentence.words, transcript);

    dicts.forEach((dict) => {
      let match;
      let regex;
      if (lang === 'jp') {
        regex = new RegExp(dict.word1, 'igm');
      } else if (IS_SAFARI) {
        // Costly operation
        regex = new RegExp(`${dict.word1}(?!${VNMESE_CHAR}+)`);
      } else {
        regex = new RegExp(`(?<!${VNMESE_CHAR}+)${dict.word1}(?!${VNMESE_CHAR}+)`, 'igm');
      }
      const exec = () => {
        if (IS_SAFARI && lang !== 'jp') {
          return XRegExp.execLb(sentence.transcript, `(?<!${VNMESE_CHAR}+)`, regex);
        } else {
          return regex.exec(sentence.transcript);
        }
      };
      while ((match = exec()) !== null) {
        regex.lastIndex += dict.word3.trim().length - match[0].length;
        mergeWords(sentence, match.index, match[0], dict.word3.trim());
      }
    });

    return {
      type: 'paragrah',
      sentence,
      legacyTranscript: transcript,
      children: [{ text: sentence.transcript }],
    };
  });

export const sentencesToSlateValueDataTool = (sentences) =>
  cleanSentencesDataTool(sentences).map((sentence) => {
    sentence.transcript = sentence.transcript.trim();
    sentence.oldTimestamps = [];
    delete sentence.words;

    return {
      type: 'paragrah',
      sentence,
      children: [{ text: sentence.transcript }],
    };
  });

export const execWord = (keyword, fullString, handleExec) => {
  let regex;
  if (IS_SAFARI) {
    regex = new RegExp(`${keyword}(?!${VNMESE_CHAR}+)`);
  } else {
    regex = new RegExp(`(?<!${VNMESE_CHAR}+)${keyword}(?!${VNMESE_CHAR}+)`, 'igm');
  }
  const exec = () => {
    if (IS_SAFARI) {
      return XRegExp.execLb(fullString, `(?<!${VNMESE_CHAR}+)`, regex);
    } else {
      return regex.exec(fullString);
    }
  };
  let match;
  while ((match = exec()) !== null) {
    handleExec(match);
  }
};

const splitIntoWordsWithSpace = (sentence) => {
  return sentence.split(/(\s+)/).filter((word) => word);
};

/**
 * @param {String} transcript
 */
const transcriptToWord = (transcript) => transcript.toLowerCase();

/**
 * @param {Word[]} words
 * @param {Number} offset
 * @param {Sentence} sentence
 * @param {String} matchString
 * @param {String} newString
 * @returns {void}
 */
const mergeWords = (sentence, offset, matchString, newString) => {
  const words = sentence.words;
  const { word: fromWord, index: fromIndex } = getWordAtOffset(words, offset, {
    skipEnd: true,
  });
  // If match text in the same word
  if (fromWord.endIndex >= offset + matchString.length) {
    fromWord.word = transcriptToWord(fromWord.word.toLowerCase().replace(matchString.toLowerCase(), newString));
    fromWord.changed = true;
    sentence.transcript =
      sentence.transcript.slice(0, offset) + newString + sentence.transcript.slice(offset + matchString.length);
    shiftWordsIndexToward(words, fromIndex, newString.length - matchString.length);
  } else {
    const { word: toWord, index: toIndex } = getWordAtOffset(words, offset + matchString.length, { wordOnly: true });
    const fullText = sentence.transcript.slice(fromWord.startIndex, toWord.endIndex);
    const newText = transcriptToWord(fullText.toLowerCase().replace(matchString.toLowerCase(), newString));
    /**
     * @type {Word}
     */
    const newWord = {
      conf: 1,
      start: fromWord.start,
      end: toWord.end,
      word: newText,
      startIndex: fromWord.startIndex,
      endIndex: toWord.endIndex,
      changed: true,
    };
    words.splice(fromIndex, toIndex - fromIndex + 1, newWord);
    sentence.transcript =
      sentence.transcript.slice(0, offset) + newString + sentence.transcript.slice(offset + matchString.length);
    shiftWordsIndexToward(words, fromIndex, newString.length - matchString.length);
  }
};

/**
 * Initial Word mapping
 * @param {(Word|WordProperties)[]} words
 * @param {String} transcript
 * @param {Boolean} first
 * @returns
 */
export const wordTranscriptMappings = (words, transcript) => {
  let transcriptIndex = 0;
  const wordWithTranscript = [];

  words = words.filter((word) => word.word.trim());
  words = _.flatten(
    words.map((word) => {
      word.word = word.word.trim();
      const splittedWords = word.word.split(' ');
      if (splittedWords.length > 1) {
        const newWords = splittedWords.map((splittedWord) => ({
          ...word,
          word: splittedWord,
        }));
        splitTimestamp(newWords);
        return newWords;
      } else {
        return word;
      }
    })
  );

  words.forEach(({ word, ...other }) => {
    word = word.trim();
    const wordRegex = new RegExp(
      `${word
        .split('')
        .map((char) => `[${char}][${PUNCTATION.source}]*`)
        .join('')}`,
      'i'
    );
    const match = wordRegex.exec(transcript.slice(transcriptIndex).toLowerCase());
    match && (transcriptIndex += match[0].length);
    const newWord = {
      ...other,
      type: 'word',
      word: match?.[0] ? transcriptToWord(match?.[0]) : word,
      startIndex: transcriptIndex - match?.[0].length,
      endIndex: transcriptIndex,
    };
    wordWithTranscript.push(newWord);
    const spaceRegex = new RegExp('( )+');
    const spaceMatch = spaceRegex.exec(transcript.slice(transcriptIndex));
    spaceMatch && (transcriptIndex += spaceMatch[0].length);
    if (spaceMatch) {
      wordWithTranscript.push({
        type: 'space',
        word: spaceMatch[0],
        startIndex: transcriptIndex - spaceMatch[0].length,
        endIndex: transcriptIndex,
      });
    }
  });
  return wordWithTranscript;
};

/**
 * @param {Word[]} words
 * @param {number} offset
 * Sentence offset:
 * * -1 will return last word;
 * @typedef {Object} GetWordAtOffsetOption
 * @property {(Boolean)=} wordOnly
 * @property {(Boolean)=} skipEnd
 * @param {GetWordAtOffsetOption} [options={}]
 */
export const getWordAtOffset = (words, offset, options = {}) => {
  if (words.length === 0) {
    words.push({
      start: 0,
      end: 0,
      conf: 1,
      endIndex: 0,
      startIndex: 0,
      word: '',
    });
    return { word: words[0], index: 0 };
  }

  if (offset === -1) {
    return { word: _.last(words), index: words.length - 1 };
  }

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (word.startIndex <= offset && word.endIndex >= offset) {
      let nextWord = words[i + 1];
      let nextWordIndex = i + 1;
      if (options.wordOnly && nextWord?.type === 'space') {
        nextWord = words[i + 2];
        nextWordIndex = i + 2;
      }
      if ((options.wordOnly && word.type !== 'word') || (options.skipEnd && offset === word.endIndex)) {
        if (!nextWord) {
          return null;
        }
        return { word: nextWord, index: nextWordIndex };
      }
      return { word, index: i };
    }
  }
  return null;
};

/**
 *
 * @param {Word[]} words
 * @param {[Number,Number]} range
 * @returns
 */
export const getWordsInRange = (words, [start, end], options, endOptions) => {
  const startWord = getWordAtOffset(words, start, options);
  const endWord = getWordAtOffset(words, end, endOptions ?? options);
  const startIndex = startWord?.index ?? 0;
  const endIndex = endWord?.index ?? words.length - 1;
  const wordsInRange = [];
  for (let i = startIndex; i <= endIndex; i++) {
    wordsInRange.push(_.merge({}, words[i], { wordIndex: i }));
  }
  return wordsInRange;
};

function shiftWordsIndexToward(words, startIndex, distance, updateFirst) {
  for (let i = startIndex; i < words.length; i++) {
    const word = words[i];
    if (i === startIndex && !updateFirst) {
      word.endIndex += distance;
    } else {
      word.startIndex += distance;
      word.endIndex += distance;
    }
  }
}

function shiftWordsIndexBackward(words, startIndex, removeFromIndex, removeToIndex) {
  const gap = removeToIndex ? removeToIndex - removeFromIndex : removeFromIndex;
  for (let i = startIndex; i < words.length; i++) {
    const word = words[i];
    if (i === startIndex) {
      word.endIndex -= gap;
    } else {
      word.startIndex -= gap;
      word.endIndex -= gap;
    }
  }
}

function insertString(a, b, position) {
  return a.substring(0, position) + b + a.substring(position);
}

export function resetNodes(editor, options) {
  const children = [...editor.children];

  Editor.withoutNormalizing(editor, () => {
    children.forEach((node) => editor.apply({ type: 'remove_node', path: [0], node }));

    if (options.nodes) {
      const nodes = Node.isNode(options.nodes) ? [options.nodes] : options.nodes;

      nodes.forEach((node, i) => editor.apply({ type: 'insert_node', path: [i], node: node }));
    }
  });
}

const getWordAtOffsetInserting = (words, offset, isSpace) => {
  if (words.length === 0) {
    words.push({
      start: 0,
      end: 0,
      conf: 1,
      endIndex: 0,
      startIndex: 0,
      word: '',
    });
    return { word: words[0], index: 0 };
  }

  if (offset === -1) {
    return { word: _.last(words), index: words.length - 1 };
  }

  const type = isSpace ? 'space' : 'word';

  for (let index = 0; index < words.length; index++) {
    const word = words[index];
    if (word.startIndex <= offset && word.endIndex >= offset) {
      let nextWord = words[index + 1];
      //
      if (offset === 0 && word.type !== type) {
        words.unshift({
          type,
          startIndex: 0,
          endIndex: 0,
        });
        return { word: words[0], index: 0 };
      }
      if (word.endIndex === offset && word.type !== type) {
        if (!nextWord) {
          words.push({
            type,
            startIndex: word.endIndex,
            endIndex: word.endIndex,
          });
          nextWord = _.last(words);
        }
        return { word: nextWord, index: index + 1 };
      }
      return { word, index };
    }
  }
  return null;
};

const splitTimestamp = (words) => {
  const start = words[0].start;
  const end = _.last(words).end;
  const duration = end - start;
  const avgDuration = duration / words.length;
  words.forEach((word, index) => {
    word.start = Math.floor((start + avgDuration * index) * 100) / 100;
    word.end = Math.floor((start + avgDuration * (index + 1)) * 100) / 100;
  });
};

const updateSplitWordTimestamp = (beforeWord, word, nextWord, oldTimestamps, startTimestamp) => {
  if (oldTimestamps) {
    for (let i = 0; i < oldTimestamps.length; i++) {
      const oldTimestamp = oldTimestamps[i];
      const [oldStart, oldEnd] = oldTimestamp;
      if (oldStart >= (beforeWord?.end ?? 0) && oldEnd <= (nextWord?.start ?? 99999999)) {
        word.start = oldStart;
        word.end = oldEnd;
        oldTimestamps.splice(i, 1);
        return;
      }
    }
  }
  if (!beforeWord && (!_.has(word, 'start') || !_.has(word, 'end'))) {
    word.start = startTimestamp;
    word.end = startTimestamp;
    return;
  }
  const len = word.start && word.end ? word.end - word.start : beforeWord.end - beforeWord.start;
  const splitTimestamp = Math.floor((beforeWord.start + len / 2) * 100) / 100;
  if (!_.has(word, 'end')) {
    word.end = beforeWord.end;
  }
  beforeWord.end = splitTimestamp;
  word.start = splitTimestamp;
};

export const getWordOnly = (words) =>
  _.some(words, (word) => !_.has(word, 'type')) ? words : words.filter((word) => word.type === 'word');

const addSentenceOldTimestamp = (sentence, timestamp) => {
  if (!sentence.oldTimestamps) {
    sentence.oldTimestamps = [];
  }

  if (_.isArray(timestamp[0])) {
    sentence.oldTimestamps.push(...timestamp);
  } else {
    sentence.oldTimestamps.push(timestamp);
  }

  sentence.oldTimestamps = _.orderBy(sentence.oldTimestamps, 0, 'asc');
};

/**
 * @param {Sentence} sentence
 * @returns {void}
 */
const normalizeSentenceWords = (sentence) => {
  // Clean empty word - add timestamp to history
  sentence.words = sentence.words.filter((word) => {
    const isEmpty = word.word.length === 0;

    if (isEmpty && word.type === 'word') {
      addSentenceOldTimestamp(sentence, [word.start, word.end]);
    }

    return !isEmpty;
  });

  // Join adjacent same type
  const normalizedWords = [];
  for (let i = 0; i < sentence.words.length; i++) {
    const word = sentence.words[i];
    if (i === 0) {
      normalizedWords.push(word);
    } else {
      const lastWord = _.last(normalizedWords);
      if (lastWord.type !== word.type) {
        normalizedWords.push(word);
      } else {
        if (word.type === 'space') {
          lastWord.word += word.word;
          lastWord.endIndex = word.endIndex;
        } else {
          lastWord.word += word.word;
          lastWord.endIndex = word.endIndex;
          lastWord.conf = 1;
          addSentenceOldTimestamp(sentence, [word.start, word.end]);
        }
      }
    }
  }

  sentence.words = normalizedWords;
};

/**
 * @param {Editor} editor
 * @returns {Editor}
 */
export const withTranscript = (editor) => {
  const { onChange } = editor;
  /**
   * @type {Sentence[] | null}
   */
  let memoizedSentences = null;

  const update = (callbacks) => {
    if (memoizedSentences) {
      memoizedSentences.forEach((sentence, index) => {
        if (sentence.wait) {
          delete sentence.wait;
          const onlyWords = sentence.words.filter((word) => word.type === 'word');
          if (!sentence.customTimestamp && onlyWords.length !== 0) {
            sentence.start = _.first(onlyWords).start;
            sentence.end = _.last(onlyWords).end;
          }
          Transforms.setNodes(
            editor,
            {
              sentence,
            },
            {
              at: [index],
            }
          );
          _.compact(callbacks).forEach((callback) => _.isFunction(callback) && callback());
        }
      });
    }
    memoizedSentences = _.cloneDeep(editor.children.map((child) => child.sentence));
  };

  const debounceUpdate = _.debounce(update, 300);

  editor.onChange = (opts) => {
    if (!memoizedSentences) {
      memoizedSentences = _.cloneDeep(editor.children.map((child) => child.sentence));
    }
    if (memoizedSentences) {
      let isUpdate = false;
      let forceUpdate = false;
      let callbacks = [];
      editor.operations.forEach((operation) => {
        switch (operation.type) {
          // OK
          case 'change_speaker': {
            const { sentence, speaker, all } = operation;
            const sentenceIndex = memoizedSentences.findIndex((memoizedSentence) =>
              _.isEqual(memoizedSentence, sentence)
            );
            if (sentenceIndex !== -1) {
              if (all) {
                const oldSpeaker = memoizedSentences[sentenceIndex].speaker;
                for (const sentence of memoizedSentences) {
                  if (sentence.speaker === oldSpeaker) {
                    sentence.speaker = speaker;
                    sentence.wait = true;
                  }
                }
              } else {
                memoizedSentences[sentenceIndex].speaker = speaker;
                memoizedSentences[sentenceIndex].wait = true;
              }
              forceUpdate = true;
            }
            break;
          }
          // OK
          case 'update_word_timestamp': {
            const { sentenceIndex, wordIndex = 0, start, end } = operation;
            const word = memoizedSentences[sentenceIndex].words[wordIndex];
            if (start) word.start = start;
            if (end) word.end = end;
            memoizedSentences[sentenceIndex].wait = true;
            forceUpdate = true;
            if (operation.callback) {
              callbacks.push(operation.callback);
            }
            break;
          }
          case 'update_sentence_start': {
            const { sentenceIndex, value } = operation;
            const sentence = memoizedSentences[sentenceIndex];
            if (sentence.end < value) {
              message.error('Start must be less than end');
              break;
            }
            sentence.customTimestamp = true;
            sentence.start = value;
            sentence.wait = true;
            forceUpdate = true;
            break;
          }
          case 'update_sentence_end': {
            const { sentenceIndex, value } = operation;
            const sentence = memoizedSentences[sentenceIndex];
            if (sentence.start > value) {
              message.error('End must be greater than start');
              break;
            }
            sentence.customTimestamp = true;
            sentence.end = value;
            sentence.wait = true;
            forceUpdate = true;
            break;
          }
          // OK
          case 'insert_text': {
            const sentenceIndex = operation.path[0];
            const sentence = memoizedSentences[sentenceIndex];
            // Check if new text has space at start or end
            const newTextWords = splitIntoWordsWithSpace(operation.text);
            let currentOffset = operation.offset;
            newTextWords.forEach((newWord) => {
              const isSpace = newWord.trim().length === 0;

              const wordWithIndex = getWordAtOffsetInserting(sentence.words, currentOffset, isSpace);
              if (wordWithIndex) {
                const { word, index } = wordWithIndex;
                if (word.type === 'word' && !word.start && !word.end) {
                  let words = [];
                  if (index === 0) {
                    word.start = sentence.start;
                    words = [word, sentence.words[index + 2]];
                  } else {
                    word.end = sentence.end;
                    words = [index !== 0 ? sentence.words[index - 2] : undefined, word];
                  }
                  if (_.compact(words).length === words.length) splitTimestamp(words);
                }
                const type = isSpace ? 'space' : 'word';

                if (word.type === type) {
                  word.word = transcriptToWord(
                    insertString(
                      sentence.transcript.slice(word.startIndex, word.endIndex),
                      newWord,
                      currentOffset - word.startIndex
                    )
                  );
                  if (type === 'word') {
                    word.conf = 1;
                  }
                  shiftWordsIndexToward(sentence.words, index, newWord.length);
                } else {
                  const splitOffset = currentOffset - word.startIndex;
                  const splitTexts = [word.word.slice(0, splitOffset), word.word.slice(splitOffset)];
                  let splitWords = splitTexts.map((text) => ({
                    ...word,
                    word: text,
                  }));
                  splitWords.splice(1, 0, {
                    type,
                    word: newWord,
                  });
                  let startIndex = word.startIndex;
                  splitWords = splitWords.map((splitWord) => {
                    const tmpSplitWord = _.cloneDeep(splitWord);
                    tmpSplitWord.startIndex = startIndex;
                    tmpSplitWord.endIndex = startIndex + tmpSplitWord.word.length;
                    startIndex = tmpSplitWord.endIndex;
                    return tmpSplitWord;
                  });

                  sentence.words.splice(index, 1, ...splitWords);
                  let nextWord;
                  let beforeWord;
                  let changeWord;
                  if (word.type === 'word') {
                    nextWord = sentence.words[index + 4];
                    beforeWord = splitWords[0];
                    changeWord = splitWords[2];
                  } else {
                    beforeWord = index !== 0 ? sentence.words[index - 1] : undefined;
                    nextWord = sentence.words[index + 3];
                    changeWord = splitWords[1];
                  }
                  updateSplitWordTimestamp(
                    beforeWord,
                    changeWord,
                    nextWord,
                    sentence.oldTimestamps,
                    sentence.start,
                    sentence.end
                  );
                  shiftWordsIndexToward(sentence.words, index + 3, newWord.length, true);
                }
              }
              sentence.transcript = insertString(sentence.transcript, newWord, currentOffset);
              currentOffset += newWord.length;
            });
            isUpdate = true;
            sentence.wait = true;
            break;
          }
          // OK
          case 'remove_text': {
            const sentenceIndex = operation.path[0];
            const sentence = memoizedSentences[sentenceIndex];
            const removeToIndex = operation.offset + operation.text.length;

            const { word: fromWord, index: fromWordIndex } = getWordAtOffset(sentence.words, operation.offset, {
              skipEnd: true,
            });
            const { word: toWord, index: toWordIndex } = getWordAtOffset(sentence.words, removeToIndex);
            if (fromWordIndex === toWordIndex) {
              fromWord.word = transcriptToWord(
                sentence.transcript.slice(fromWord.startIndex, operation.offset) +
                  sentence.transcript.slice(removeToIndex, fromWord.endIndex)
              );
              fromWord.conf = 1;
            } else {
              fromWord.word = transcriptToWord(sentence.transcript.slice(fromWord.startIndex, operation.offset));
              fromWord.endIndex = operation.offset;
              fromWord.conf = 1;
              for (let i = fromWordIndex + 1; i < toWordIndex; i++) {
                const word = sentence.words[i];
                word.startIndex = operation.offset;
                word.endIndex = operation.offset;
                word.word = '';
              }
              toWord.word = transcriptToWord(sentence.transcript.slice(removeToIndex, toWord.endIndex));
              toWord.startIndex = operation.offset;
              toWord.conf = 1;
            }
            sentence.transcript =
              sentence.transcript.slice(0, operation.offset) + sentence.transcript.slice(removeToIndex);
            sentence.wait = true;
            shiftWordsIndexToward(sentence.words, toWordIndex, operation.offset - removeToIndex);
            // Normalize empty word
            normalizeSentenceWords(sentence);
            isUpdate = true;
            break;
          }
          // OK
          case 'merge_node': {
            if (operation.path.length === 1) {
              const sourceSentence = memoizedSentences[operation.path[0]];
              const destSentence = memoizedSentences[operation.path[0] - 1];
              const oldDestWordLength = destSentence.words.length;
              destSentence.transcript += sourceSentence.transcript;
              destSentence.words.push(..._.cloneDeep(sourceSentence.words));
              destSentence.wait = true;
              shiftWordsIndexToward(
                destSentence.words,
                oldDestWordLength,
                destSentence.transcript.length - sourceSentence.transcript.length,
                destSentence.transcript,
                true
              );
              if (sourceSentence.oldTimestamps && sourceSentence.oldTimestamps.length > 0) {
                addSentenceOldTimestamp(destSentence, sourceSentence.oldTimestamps);
              }
              memoizedSentences.splice(operation.path[0], 1);
              normalizeSentenceWords(destSentence);
              forceUpdate = true;
            }
            break;
          }
          // OK
          case 'split_node': {
            if (operation.path.length === 2) {
              const sentenceIndex = operation.path[0];
              const sentence = memoizedSentences[sentenceIndex];
              const wordWithIndex = getWordAtOffset(sentence.words, operation.position);
              if (wordWithIndex) {
                const { index, word } = wordWithIndex;
                word.word = transcriptToWord(sentence.transcript.slice(word.startIndex, operation.position));
                const newSentence = _.cloneDeep(sentence);
                newSentence.words = _.cloneDeep(sentence.words.splice(index + (operation.position === 0 ? 0 : 1)));
                const newSentenceFirstWord = _.first(newSentence.words);
                if (newSentenceFirstWord) {
                  newSentenceFirstWord.word =
                    sentence.transcript.slice(operation.position, word.endIndex) + newSentenceFirstWord.word;
                  shiftWordsIndexBackward(newSentence.words, 0, newSentenceFirstWord.startIndex);
                  newSentenceFirstWord.startIndex = 0;
                  newSentenceFirstWord.type = 'word';
                  if (_.isUndefined(newSentenceFirstWord.start)) {
                    newSentenceFirstWord.start = sentence.start;
                  }
                  if (_.isUndefined(newSentenceFirstWord.end)) {
                    newSentenceFirstWord.end = sentence.end;
                  }
                  shiftWordsIndexToward(
                    newSentence.words,
                    0,
                    word.endIndex - operation.position,
                    newSentence.transcript.slice(operation.position)
                  );
                } else {
                  const newWord = _.defaults(
                    {
                      ...word,
                      startIndex: 0,
                      endIndex: word.endIndex - operation.position,
                      type: 'word',
                      word: transcriptToWord(sentence.transcript.slice(operation.position, word.endIndex)),
                    },
                    {
                      start: sentence.start,
                      end: sentence.end,
                    }
                  );
                  newSentence.words.push(newWord);
                }
                newSentence.start = newSentenceFirstWord?.start ?? newSentence.end;
                newSentence.transcript = newSentence.transcript.slice(operation.position);
                newSentence.wait = true;
                memoizedSentences.splice(sentenceIndex + 1, 0, newSentence);
                // Update old sentence end
                word.endIndex = operation.position;
                sentence.end = _.last(sentence.words)?.end ?? sentence.start;
                sentence.transcript = sentence.transcript.slice(0, operation.position);
                sentence.wait = true;
                forceUpdate = true;
              }
            }
            break;
          }
          // OK
          case 'remove_node':
            memoizedSentences.splice(operation.path[0], 1);
            isUpdate = true;
            break;
          // OK
          case 'insert_node':
            const sentence = _.cloneDeep(operation.node.sentence);
            sentence.wait = true;
            memoizedSentences.splice(operation.path[0], 0, sentence);
            isUpdate = true;
            break;
          default:
        }
      });
      if (isUpdate || forceUpdate) {
        if (forceUpdate) {
          update(callbacks);
        } else {
          debounceUpdate(callbacks);
        }
      }
    }
    onChange(opts);
  };

  return editor;
};

/**
 * @param {Editor} editor
 * @returns {Editor}
 */
export const withTranscriptDataTool = (editor) => {
  const { onChange } = editor;
  /**
   * @type {Sentence[] | null}
   */
  let memoizedSentences = null;

  const update = (callbacks) => {
    if (memoizedSentences) {
      memoizedSentences.forEach((sentence, index) => {
        if (sentence.wait) {
          delete sentence.wait;
          Transforms.setNodes(
            editor,
            {
              sentence,
            },
            {
              at: [index],
            }
          );
          _.compact(callbacks).forEach((callback) => _.isFunction(callback) && callback());
        }
      });
    }
    memoizedSentences = _.cloneDeep(editor.children.map((child) => child.sentence));
  };

  const debounceUpdate = _.debounce(update, 300);

  editor.onChange = (opts) => {
    if (!memoizedSentences) {
      memoizedSentences = _.cloneDeep(editor.children.map((child) => child.sentence));
    }
    if (memoizedSentences) {
      let isUpdate = false;
      let forceUpdate = false;
      let callbacks = [];
      editor.operations.forEach((operation) => {
        switch (operation.type) {
          // OK
          case 'change_speaker': {
            const { sentence, speaker, all } = operation;
            const sentenceIndex = memoizedSentences.findIndex((memoizedSentence) =>
              _.isEqual(memoizedSentence, sentence)
            );
            if (sentenceIndex !== -1) {
              if (all) {
                const oldSpeaker = memoizedSentences[sentenceIndex].speaker;
                for (const sentence of memoizedSentences) {
                  if (sentence.speaker === oldSpeaker) {
                    sentence.speaker = speaker;
                    sentence.wait = true;
                  }
                }
              } else {
                memoizedSentences[sentenceIndex].speaker = speaker;
                memoizedSentences[sentenceIndex].wait = true;
              }
              forceUpdate = true;
            }
            break;
          }
          case 'update_sentence_start': {
            const { sentenceIndex, value } = operation;
            const sentence = memoizedSentences[sentenceIndex];
            if (sentence.end < value) {
              message.error('Start must be less than end');
              break;
            }
            sentence.customTimestamp = true;
            sentence.start = value;
            sentence.wait = true;
            forceUpdate = true;
            break;
          }
          case 'update_sentence_end': {
            const { sentenceIndex, value } = operation;
            const sentence = memoizedSentences[sentenceIndex];
            if (sentence.start > value) {
              message.error('End must be greater than start');
              break;
            }
            sentence.customTimestamp = true;
            sentence.end = value;
            sentence.wait = true;
            forceUpdate = true;
            break;
          }
          // OK
          case 'insert_text':
          case 'remove_text': {
            const sentenceIndex = operation.path[0];
            const sentence = memoizedSentences[sentenceIndex];
            sentence.transcript = editor.children[sentenceIndex]?.children[0]?.text;
            isUpdate = true;
            sentence.wait = true;
            break;
          }
          // OK
          case 'merge_node': {
            if (operation.path.length === 1) {
              const destSentence = memoizedSentences[operation.path[0] - 1];
              destSentence.transcript = editor.children[operation.path[0] - 1].children[0]?.text;
              destSentence.wait = true;
              memoizedSentences.splice(operation.path[0], 1);
              forceUpdate = true;
            }
            break;
          }
          // OK
          case 'split_node': {
            if (operation.path.length === 2) {
              const sentenceIndex = operation.path[0];
              const sentence = memoizedSentences[sentenceIndex];
              sentence.transcript = editor.children[operation.path[0]].children[0].text;
              sentence.wait = true;
              const newSentence = _.cloneDeep(sentence);
              newSentence.transcript = editor.children[operation.path[0] + 1].children[0].text;
              newSentence.wait = true;
              memoizedSentences.splice(sentenceIndex + 1, 0, newSentence);
              forceUpdate = true;
            }
            break;
          }
          // OK
          case 'remove_node':
            memoizedSentences.splice(operation.path[0], 1);
            isUpdate = true;
            break;
          // OK
          case 'insert_node':
            const sentence = _.cloneDeep(operation.node.sentence);
            sentence.wait = true;
            memoizedSentences.splice(operation.path[0], 0, sentence);
            isUpdate = true;
            break;
          default:
        }
      });
      if (isUpdate || forceUpdate) {
        if (forceUpdate) {
          update(callbacks);
        } else {
          debounceUpdate(callbacks);
        }
      }
    }
    onChange(opts);
  };

  return editor;
};

export const cleanSentences = (sentences, wordOnly) => {
  return _.cloneDeep(sentences).map((sentence) => {
    if (_.isString(sentence.start)) {
      sentence.start = parseFloat(sentence.start);
    }
    if (_.isString(sentence.end)) {
      sentence.end = parseFloat(sentence.end);
    }
    sentence.words = (wordOnly ? getWordOnly(sentence.words) : sentence.words).map((word) => {
      word.word = word.word.toLowerCase();
      return _.omit(word, ['startIndex', 'endIndex', 'changed', 'lastRemoved', 'type']);
    });
    sentence.speaker = sentence.speaker.toString().trim();
    const redundantSpeakerMatch = /speaker ([0-9]+)$/i.exec(sentence.speaker);
    if (redundantSpeakerMatch) {
      sentence.speaker = redundantSpeakerMatch[1];
    }
    sentence.words = sentence.words.filter((word) => word.word.trim());
    return _.omit(sentence, ['wait', 'oldTimestamps', 'id']);
  });
};

export const cleanSentencesDataTool = (sentences) => {
  return _.cloneDeep(sentences).map((sentence) => {
    if (_.isString(sentence.start)) {
      sentence.start = parseFloat(sentence.start);
    }
    if (_.isString(sentence.end)) {
      sentence.end = parseFloat(sentence.end);
    }
    delete sentence.words;
    sentence.speaker = sentence.speaker.toString().trim();
    const redundantSpeakerMatch = /speaker ([0-9]+)$/i.exec(sentence.speaker);
    if (redundantSpeakerMatch) {
      sentence.speaker = redundantSpeakerMatch[1];
    }
    return _.omit(sentence, ['wait', 'oldTimestamps', 'id', 'customTimestamp']);
  });
};

export const changeTime = (vidElement, value) => {
  if (vidElement) {
    vidElement.currentTime = _.clamp(value, 0, vidElement.duration);
    if (vidElement.paused) {
      vidElement.play();
    }
  }
};

export const DEFAULT_KEYWORD_BACKGROUND = '#FED130';
export const DEFAULT_KEYWORD_TEXT = '#000';

export const getKeywordBackgroundColor = (keyword, keywordCategory) => {
  return keyword?.customColor ? keyword.backgroundColor : keywordCategory.backgroundColor ?? DEFAULT_KEYWORD_BACKGROUND;
};

export const getKeywordTextColor = (keyword, keywordCategory) => {
  return keyword?.customColor ? keyword.textColor : keywordCategory.textColor ?? DEFAULT_KEYWORD_TEXT;
};

export const getKeywordStyles = (keyword, keywordCategory) => ({
  color: getKeywordTextColor(keyword, keywordCategory),
  backgroundColor: getKeywordBackgroundColor(keyword, keywordCategory),
});

import { useMemo } from 'react';
import useAuth from './useAuth';

export default function useDataToolRole() {
  const { user } = useAuth();

  const data = useMemo(() => {
    if (user) {
      if (user.email.endsWith('@admin.ai')) {
        return 'admin';
      } else {
        if (user.email.endsWith('@annotator.ai')) {
          return 'annotator';
        } else if (user.email.endsWith('@reviewer.ai')) {
          return 'reviewer';
        } else if (user.email.endsWith('@approver.ai')) {
          return 'approver';
        } else if (user.email.endsWith('@tool.ai')) {
          return 'tool';
        }
        return null;
      }
    } else {
      return null;
    }
  }, [user]);

  return data;
}

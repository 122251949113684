import { CONF_HIGHLIGHT_THRESHOLD, IS_JP_ASR, IS_NTTDATA, IS_SAFARI, JOIN_CHAR, VNMESE_CHAR } from 'utils/constants';
import Diff from 'text-diff';
import { message } from 'antd';
import { XRegExp } from './utils';
import _ from 'lodash';
import * as Difff from 'diff';

const diff = new Diff();

export const getTranscriptEditedDocId = (dataToolRole) => {
  let editedDocId = '';
  switch (dataToolRole) {
    case 'annotator':
    case 'reviewer':
    case 'tool':
      editedDocId = dataToolRole;
      break;
    default:
      editedDocId = 'user';
  }
  return editedDocId;
};

const convertPrimitiveString = (str) => (str ? str.toLowerCase().replace(/\.|,|\?|!|。|、/g, '') : undefined);

const compareTransWithWord = (transcript, word) => {
  return transcript && word && convertPrimitiveString(transcript) === convertPrimitiveString(word);
};

const checkStrIncludeLeftToRight = (str, subStr) => {
  const subStrSplit = convertPrimitiveString(subStr);
  return convertPrimitiveString(str)?.startsWith(subStrSplit);
};

export function getWordFromSelection(sentences, sentenceOffset, anchorOffset) {
  let curCharCount = 0;
  let curSentenceIdx = sentenceOffset;
  let wordResult = sentences[curSentenceIdx].words[0];
  const joinCharLength = JOIN_CHAR.length;
  while (curCharCount < anchorOffset && curSentenceIdx < sentences.length) {
    const curSentence = sentences[curSentenceIdx];
    const words = curSentence.words;
    const transcript = curSentence.transcript.split(JOIN_CHAR);
    let leftBound = 0;
    let wordIdx = 0;
    let rightBound = 1;
    while (leftBound < transcript.length && curCharCount < anchorOffset) {
      if (leftBound >= rightBound) {
        rightBound = leftBound + 1;
      }
      const curTranscript = transcript.slice(leftBound, rightBound).join(JOIN_CHAR);
      let curWord = words[wordIdx];
      while ((!curWord || curWord.word === '') && wordIdx < words.length) {
        wordIdx++;
        curWord = words[wordIdx];
      }
      if (convertPrimitiveString(transcript[leftBound]) === '') {
        curCharCount += transcript[leftBound].length + joinCharLength;
        leftBound += 1;
        rightBound += 1;
        continue;
      }
      if (compareTransWithWord(curTranscript, curWord?.word)) {
        leftBound = rightBound;
        rightBound += 1;
        curCharCount += curTranscript.length + joinCharLength;
        wordIdx++;
      } else {
        // Check if transcript is a substring of the word
        if (checkStrIncludeLeftToRight(curWord?.word, curTranscript)) {
          rightBound += 1;
        } else {
          // Append to existing segment or create new segment
          curCharCount += curTranscript.length + joinCharLength;
          leftBound += 1;
        }
      }
    }
    curSentenceIdx++;
    if (wordIdx >= words.length) {
      wordResult = sentences[curSentenceIdx].words[0];
    } else {
      wordResult = words[wordIdx];
    }
  }

  return wordResult;
}

export function convertToTranscriptSegments(transcripts, words, sentenceOffset, highlight) {
  const transcript = transcripts
    .filter((t) => t)
    .join(JOIN_CHAR)
    .split(JOIN_CHAR);
  const flatWords = words.flat();
  const highlightOffset =
    highlight[0] < sentenceOffset ? -1 : words.slice(0, highlight[0] - sentenceOffset).flat().length;
  let transcriptSegments = [];
  let leftBound = 0;
  let wordIdx = 0;
  let rightBound = 1;
  while (leftBound < transcript.length) {
    if (leftBound >= rightBound) {
      rightBound = leftBound + 1;
    }
    const curTranscript = transcript.slice(leftBound, rightBound).join(JOIN_CHAR);
    let curWord = flatWords[wordIdx];
    while ((!curWord || curWord.word === '') && wordIdx < flatWords.length) {
      wordIdx++;
      curWord = flatWords[wordIdx];
    }
    const lastSegment = transcriptSegments[transcriptSegments.length - 1];
    if (convertPrimitiveString(transcript[leftBound]) === '') {
      if (lastSegment?.type === 'normal' || lastSegment?.type === 'unknown') {
        lastSegment.transcript += transcript[leftBound];
      } else {
        transcriptSegments.push({
          type: 'normal',
          transcript: transcript[leftBound],
        });
      }
      leftBound += 1;
      rightBound += 1;
      continue;
    }
    if (compareTransWithWord(curTranscript, curWord?.word)) {
      // Check highlight
      if (highlightOffset !== -1 && wordIdx === highlightOffset + highlight[1]) {
        transcriptSegments.push({
          transcript: curTranscript,
          type: 'highlight',
        });
      } else {
        const pushedType = curWord.changed
          ? 'unknown'
          : _.isUndefined(curWord.conf) || curWord.conf > CONF_HIGHLIGHT_THRESHOLD
          ? 'normal'
          : 'low-conf';
        // Append to existing segment or create new segment
        if (lastSegment?.type === pushedType) {
          lastSegment.transcript += `${JOIN_CHAR}${curTranscript}`;
        } else {
          transcriptSegments.push({
            type: pushedType,
            transcript: curTranscript,
          });
        }
      }
      leftBound = rightBound;
      rightBound += 1;
      wordIdx++;
    } else {
      // Check if transcript is a substring of the word
      if (checkStrIncludeLeftToRight(curWord?.word, curTranscript)) {
        rightBound += 1;
      } else {
        // Append to existing segment or create new segment
        if (lastSegment?.type === 'unknown') {
          lastSegment.transcript += `${JOIN_CHAR}${curTranscript}`;
        } else {
          transcriptSegments.push({
            type: 'unknown',
            transcript: JOIN_CHAR === '' ? curTranscript[0] : curTranscript,
          });
        }
        leftBound += 1;
      }
    }
  }

  return transcriptSegments;
}

function getSpacesBetween(last, cur) {
  const isOnlySpaces = (str) => str.trim().length === 0;
  const lastTrailingSpaces = last.match(/\s+$/g)?.length || 0;
  const curLeadingSpaces = isOnlySpaces(cur) ? 0 : cur.match(/^\s+/g)?.length || 0;
  return lastTrailingSpaces + curLeadingSpaces;
}

function semanticDiff(originValue, changedValue) {
  const diffed = diff.main(originValue, changedValue);
  diff.cleanupSemantic(diffed);
  if (diffed.length === 1 && diffed[0][0] === 0) return diffed;

  if (JOIN_CHAR === '') return diffed;

  let lastKept = null;
  let leadingSliced = -2;
  let lastNotKept = [];
  const additionalDiffs = [];
  const filler = diffed[diffed.length - 1][0] !== 0 ? [0, ''] : [];

  [...diffed, filler].forEach((d, index) => {
    const [type, text] = d;
    if (type === 0) {
      if (lastNotKept.length > 0) {
        const spaces = lastNotKept.map((l) => ({
          leading: lastKept ? getSpacesBetween(lastKept[1], l.d[1]) : -1,
          trailing: getSpacesBetween(l.d[1], text),
        }));

        const isConcatLeading = spaces.some((s) => s.leading === 0);
        const isConcatTrailing = spaces.some((s) => s.trailing === 0);
        let trailingSliced = -2;
        lastNotKept.forEach((last) => {
          if (isConcatLeading) {
            leadingSliced = lastKept[1].lastIndexOf(' ');
            last.d[1] = leadingSliced === -1 ? lastKept[1] + last.d[1] : lastKept[1].slice(leadingSliced) + last.d[1];
          }

          if (isConcatTrailing) {
            trailingSliced = text.indexOf(' ');
            last.d[1] += text.slice(0, trailingSliced < 0 ? undefined : trailingSliced);
          }
        });
        if (lastNotKept.length === 1 && (trailingSliced !== -2 || leadingSliced !== -2)) {
          let textChanged =
            leadingSliced !== -2 ? (leadingSliced === -1 ? lastKept[1] : lastKept[1].slice(leadingSliced)) : '';
          textChanged += trailingSliced !== -2 ? text.slice(0, trailingSliced < 0 ? undefined : trailingSliced) : '';

          const index = lastNotKept[0].index + (lastNotKept[0].d[0] === 1 ? 0 : 1);
          additionalDiffs.push({
            diff: [-lastNotKept[0].d[0], textChanged],
            index,
          });
        }
        if (trailingSliced !== -2) {
          d[1] = trailingSliced === -1 ? '' : d[1].slice(trailingSliced);
        }
      }
      if (lastKept && leadingSliced !== -2) {
        lastKept[1] = leadingSliced === -1 ? '' : lastKept[1].slice(0, leadingSliced);
      }
      lastNotKept = [];
      lastKept = d;
      leadingSliced = -2;
    } else {
      lastNotKept.push({ d, index });
    }
  });

  additionalDiffs.forEach((d, index) => {
    diffed.splice(d.index + index, 0, d.diff);
  });

  let concat = false;
  let lastKeptConcat = null;
  let lastNotKeptConcat = [];
  diffed.forEach((d, index) => {
    if (d[0] === 0) {
      let leadingConcat = lastNotKeptConcat.some((l) =>
        lastKeptConcat ? getSpacesBetween(lastKeptConcat[1], l[1]) === 0 : 0
      );
      if (leadingConcat) {
        const cutIndex = lastKeptConcat[1].lastIndexOf(' ');
        lastNotKeptConcat.forEach((l) => {
          l[1] = lastKeptConcat[1].slice(cutIndex) + l[1];
        });
        lastKeptConcat[1] = lastKeptConcat[1].slice(0, cutIndex);
        leadingConcat = lastNotKeptConcat.some((l) => getSpacesBetween(lastKeptConcat[1], l[1]) === 0);
      }

      concat = lastNotKeptConcat.some((l) => getSpacesBetween(l[1], d[1]) === 0);
      if (concat) {
        while (concat && diffed[index][1]) {
          const cutIndex = diffed[index][1].indexOf(' ');
          lastNotKeptConcat.forEach((l) => {
            l[1] += diffed[index][1].slice(0, cutIndex < 0 ? undefined : cutIndex + 1);
          });
          diffed[index][1] = cutIndex === -1 ? '' : diffed[index][1].slice(cutIndex + 1);
          concat = lastNotKeptConcat.some((l) => getSpacesBetween(l[1], d[1]) === 0);
        }
        if (concat) diffed[index] = null;
        else {
          lastNotKeptConcat = [];
          lastKeptConcat = d;
        }
      } else {
        lastNotKeptConcat = [];
        lastKeptConcat = d;
      }
    } else {
      if (concat && lastNotKeptConcat.length > 0) {
        lastNotKeptConcat.forEach((last) => {
          if (last[0] === d[0]) {
            last[1] += d[1];
            diffed[index] = null;
          }
        });
      } else {
        lastNotKeptConcat.push(d);
      }
    }
  });

  return diffed.filter((d) => d);
}

function segmentsToEditedSegments(sentences, segments) {
  const edited = JSON.parse(JSON.stringify(sentences));
  const editedTranscripts = edited.map(() => []);

  const addedBatches = [];
  const removedBatches = [];

  segments.forEach((segment, index) => {
    if (segment.type === 'removed' || (segment.type === 'join-remove' && index === segments.length - 1)) {
      if (!segment.at) return;
      if (IS_JP_ASR) {
        const lastRemovedBatch = _.last(removedBatches);
        if (
          lastRemovedBatch &&
          lastRemovedBatch.index === segment.at[0] &&
          (lastRemovedBatch.to === segment.at[1] || lastRemovedBatch.to + 1 === segment.at[1])
        ) {
          lastRemovedBatch.to = segment.at[1];
          lastRemovedBatch.transcript += segment.transcript;
        } else {
          removedBatches.push({
            index: segment.at[0],
            from: segment.at[1],
            to: segment.at[1],
            transcript: segment.transcript,
          });
        }
      }
      edited[segment.at[0]].words.splice(segment.at[1], 1, null);
      if (!editedTranscripts[segment.at[0]].length) editedTranscripts[segment.at[0]].push('');
    } else if (segment.type === 'added' || segment.type === 'kept') {
      if (!segment.at) return;
      if (IS_JP_ASR && segment.type === 'added' && !_.isEmpty(segment.transcript.trim())) {
        const lastAddedBatch = _.last(addedBatches);
        if (
          lastAddedBatch &&
          lastAddedBatch.index === segment.at[0] &&
          (lastAddedBatch.to === segment.at[1] || lastAddedBatch.to + 1 === segment.at[1])
        ) {
          lastAddedBatch.to = segment.at[1];
          lastAddedBatch.transcript += segment.transcript;
        } else {
          addedBatches.push({
            index: segment.at[0],
            from: segment.at[1],
            to: segment.at[1],
            transcript: segment.transcript,
          });
        }
      }
      editedTranscripts[segment.at[0]].push(segment.transcript);
    } else if (segment.type === 'ignored') {
      return;
    } else {
      const msg = `BUG: Invalid segment type: ${segment.type} from ${segment}`;
      message.error(msg);
      console.error(msg);
    }
  });

  if (IS_JP_ASR) {
    const newWords = [];
    let addIndex = -1;
    for (let i = 0; i < removedBatches.length; i++) {
      let firstPush = false;
      for (let j = addIndex + 1; j < addedBatches.length; j++) {
        const addedBatch = addedBatches[j];
        const removeBatch = removedBatches[i];
        if (
          firstPush &&
          newWords.length > 0 &&
          removeBatch.index === addedBatch.index &&
          removeBatch.to >= addedBatch.to &&
          removeBatch.from <= addedBatch.from
        ) {
          const lastNewWord = _.last(newWords);
          lastNewWord.word += addedBatch.transcript;
        } else {
          if (firstPush) {
            break;
          } else {
            firstPush = true;
          }
          newWords.push({
            index: removeBatch.index,
            from: removeBatch.from,
            to: removeBatch.to,
            start: sentences[removeBatch.index].words[removeBatch.from].start,
            end: sentences[removeBatch.index].words[removeBatch.to].end,
            word: addedBatch.transcript,
            changed: true,
          });
        }
        addIndex = j;
      }
    }

    newWords.forEach((newWord) => {
      edited[newWord.index].words[newWord.from] = _.omit(newWord, ['index', 'from', 'to']);
    });
  }

  edited.forEach((sentence, index) => {
    if (IS_JP_ASR) {
      if (sentence.words && !_.isEmpty(sentence.words)) {
        sentence.words = _.compact(sentence.words);
      }
    }
    if (!editedTranscripts[index].length) return;
    sentence.transcript = editedTranscripts[index].filter((t) => t).join(JOIN_CHAR);
  });

  // Update words
  if (!IS_JP_ASR) {
    edited.forEach((sentence, sentenceIndex) => {
      if (!editedTranscripts[sentenceIndex].length) return;
      // Get transcript and word diffs
      const cleanTranscript = (convertPrimitiveString(sentence.transcript) ?? '').replace(/\s\s+/g, JOIN_CHAR); // remove double spaces
      const fullWord = (
        convertPrimitiveString(sentence.words.map((word) => word?.word ?? '').join(JOIN_CHAR)) ?? ''
      ).replace(/\s\s+/g, JOIN_CHAR); // remove double spaces
      const fullDiffed = _.reduce(
        _.compact(
          Difff.diffWords(cleanTranscript, fullWord).map((segment) => {
            if (!(convertPrimitiveString(segment.value) ?? '').trim()) return null;
            if (segment.removed) {
              return [-1, segment.value];
            } else if (segment.added) {
              return [1, segment.value];
            }
            return [0, segment.value];
          })
        ),
        (prevSegments, currentSegment, i) => {
          if (i === 0) return [currentSegment];

          const lastSegment = _.last(prevSegments);

          if (lastSegment[0] === currentSegment[0]) {
            lastSegment[1] += JOIN_CHAR + currentSegment[1];
          } else {
            prevSegments.push(currentSegment);
          }
          return prevSegments;
        },
        []
      );

      // Previous word that has not changed anchor
      let prevValidWordIdx = 0;
      let nextValidWordIdx = null;
      // Mark word index
      sentence.words = sentence.words
        .map((word, index) => (word ? Object.assign(word, { realIndex: index }) : word))
        .filter((word) => word);
      let tmpWords = _.cloneDeep(sentence.words);
      let addedWords = 0;
      fullDiffed
        .filter((segment) => segment[0] !== 1)
        .forEach((segment, index, diffed) => {
          // Check new segment only
          if (segment[0] === -1) {
            // Calculate previous valid word index
            const prevSegment = diffed[index - 1];
            if (index !== 0 && prevSegment) {
              // Loop each word and adjacent from segment start to word for getting
              // previous segment word index (avoid case word with 2 more words)
              for (let wordIdx = prevValidWordIdx; wordIdx < sentence.words.length; wordIdx++) {
                if (
                  compareTransWithWord(
                    prevSegment[1].trim(),
                    sentence.words
                      .slice(prevValidWordIdx, wordIdx + 1)
                      .map((word) => word.word)
                      .join(JOIN_CHAR)
                      .trim()
                  )
                ) {
                  prevValidWordIdx = wordIdx;
                  break;
                }
              }
            }

            // Calculate next valid word index
            const nextSegment = fullDiffed[index + 1];
            if (nextSegment && nextSegment[0] === 1) {
              // Loop each word and adjacent from segment start to word for getting
              // previous segment word index (avoid case word with 2 more words)
              for (let wordIdx = prevValidWordIdx + 1; wordIdx < sentence.words.length; wordIdx++) {
                if (
                  compareTransWithWord(
                    nextSegment[1].trim(),
                    sentence.words
                      .slice(prevValidWordIdx + 1, wordIdx + 1)
                      .map((word) => word.word)
                      .join(JOIN_CHAR)
                      .trim()
                  )
                ) {
                  nextValidWordIdx = wordIdx;
                  break;
                }
              }
            } else {
              nextValidWordIdx = prevValidWordIdx + 1;
            }

            // Get word index before this segment - null when this segment is the first one
            let prevValidWordRealIndex =
              index !== 0 ? (sentence.words[prevValidWordIdx] ? sentence.words[prevValidWordIdx].realIndex : 0) : null;

            // Get word index after this segment - null when this segment is the last one
            let nextValidWordRealIndex =
              index !== diffed.length - 1
                ? sentence.words[nextValidWordIdx]
                  ? sentence.words[nextValidWordIdx].realIndex
                  : sentence.words.length - 1
                : null;

            // Split transcript into words
            const currentSegmentTranscriptWords = segment[1].trim().split(JOIN_CHAR);

            const updateSegmentWords = (prevValidWordRealIndex, nextValidWordRealIndex) => {
              if (nextValidWordIdx !== prevValidWordIdx + 1) {
                tmpWords = tmpWords.filter((_, i) => !(i > prevValidWordIdx && i < nextValidWordIdx));
              }
              if (prevValidWordRealIndex !== -1 && nextValidWordRealIndex - prevValidWordRealIndex === 1) {
                const word = sentences[sentenceIndex].words[prevValidWordRealIndex];
                const duration = word.end - word.start;
                let newWords = [];
                [word.word, ...currentSegmentTranscriptWords].forEach((transcriptWord, i) => {
                  const newWord = {
                    changed: i !== 0,
                    conf: 1,
                    start: word.start + (duration / currentSegmentTranscriptWords.length) * i,
                    end: word.start + (duration / currentSegmentTranscriptWords.length) * (i + 1),
                    word: convertPrimitiveString(transcriptWord),
                  };
                  newWords.push(newWord);
                });
                tmpWords.splice(prevValidWordRealIndex === -1 ? 0 : prevValidWordIdx + addedWords, 1, ...newWords);
                addedWords += currentSegmentTranscriptWords.length;
              }
              // If new segment words count equals words count between prev segment and after segment
              // mark new each word to lost word
              else if (nextValidWordRealIndex - prevValidWordRealIndex - 1 === currentSegmentTranscriptWords.length) {
                let newWords = [];
                for (let i = prevValidWordRealIndex + 1; i < nextValidWordRealIndex; i++) {
                  const newWord = _.assign(_.cloneDeep(sentences[sentenceIndex].words[i]), {
                    changed: true,
                    word: currentSegmentTranscriptWords[newWords.length],
                  });
                  newWords.push(newWord);
                }
                tmpWords.splice(
                  prevValidWordIdx + addedWords + (prevValidWordRealIndex === -1 ? 0 : 1),
                  0,
                  ...newWords
                );
                addedWords += newWords.length;
              }
              // If not equal divided timestamp equally
              else {
                const fillWordStart = sentences[sentenceIndex].words[prevValidWordRealIndex + 1].start;
                const fillWordEnd = sentences[sentenceIndex].words[nextValidWordRealIndex - 1].end;
                const duration = fillWordEnd - fillWordStart;
                let newWords = [];
                currentSegmentTranscriptWords.forEach((transcriptWord, i) => {
                  const newWord = {
                    changed: true,
                    conf: 1,
                    start: fillWordStart + (duration / currentSegmentTranscriptWords.length) * i,
                    end: fillWordStart + (duration / currentSegmentTranscriptWords.length) * (i + 1),
                    word: convertPrimitiveString(transcriptWord),
                  };
                  newWords.push(newWord);
                });
                tmpWords.splice(
                  prevValidWordIdx + addedWords + (prevValidWordRealIndex === -1 ? 0 : 1),
                  0,
                  ...newWords
                );
                addedWords += newWords.length;
              }
            };

            // If first segment change
            if (_.isNull(prevValidWordRealIndex)) {
              // Entire sentence update
              if (_.isNull(nextValidWordRealIndex)) {
                updateSegmentWords(-1, sentences[sentenceIndex].words.length);
              } else {
                updateSegmentWords(-1, sentence.words[prevValidWordIdx].realIndex);
              }
            }
            // If last segment change merge with last word
            else if (_.isNull(nextValidWordRealIndex)) {
              updateSegmentWords(prevValidWordRealIndex, sentences[sentenceIndex].words.length);
            } else {
              updateSegmentWords(prevValidWordRealIndex, nextValidWordRealIndex);
            }

            // Go to next word
            prevValidWordIdx = nextValidWordIdx - (index === 0 ? 1 : 0);
            nextValidWordIdx = null;
          }
        });

      // Update sentence words
      sentence.words = tmpWords;
    });
  } else {
  }

  return edited;
}

export function cleanSentenceWords(sentences) {
  return sentences.map((sentence) => {
    sentence.words = sentence.words.map((word) => _.omit(word, ['changed', 'realIndex']));
    return sentence;
  });
}

export function diffToSegments(sentences, sentenceOffsets, changedValues, returnSegments = false) {
  if (sentences.length === 0) return sentences;
  let segments = [];
  sentenceOffsets.forEach((sentenceOffset, segIdx) => {
    const chosenSentences = sentences.slice(sentenceOffset[0], sentenceOffset[1] + 1);
    const originValue = chosenSentences.map((s) => s.transcript).join(JOIN_CHAR);
    const diffed = semanticDiff(originValue, changedValues[segIdx]);

    if (diffed.length === 1 && diffed[0][0] === 0) return;

    const markedWords = chosenSentences
      .map((s, index) =>
        !s.words || s.words.length === 0
          ? [
              {
                word: '',
                empty: true,
                index: sentenceOffset[0] + index,
              },
            ]
          : s.words.map((w) => ({ ...w, index: sentenceOffset[0] + index }))
      )
      .flat();

    let wordsFlatIdx = 0;
    let wordsIdx = 0;
    diffed.forEach((part) => {
      const [type, text] = part;
      const textSplit = text.split(JOIN_CHAR);
      for (let i = 0; i < textSplit.length; i++) {
        const lastSeg = segments[segments.length - 1];
        if (type !== -1 && convertPrimitiveString(textSplit[i]) === '') {
          if (lastSeg?.type === 'join' || lastSeg?.type === 'join-remove') {
            lastSeg.transcript += textSplit[i];
          } else {
            segments.push({
              type: 'kept',
              transcript: textSplit[i],
              at: lastSeg?.at,
            });
          }
          continue;
        }
        let curWord = markedWords[wordsFlatIdx];
        while (curWord?.word === '' && !curWord?.empty) {
          wordsFlatIdx++;
          wordsIdx++;
          curWord = markedWords[wordsFlatIdx];
        }
        if (curWord?.index !== markedWords[wordsFlatIdx - 1]?.index) {
          wordsIdx = 0;
        }

        const curWordText =
          (lastSeg?.type === 'join' && type === 0) || (lastSeg?.type === 'join-remove' && type === -1)
            ? [lastSeg.transcript, textSplit[i]].join(JOIN_CHAR)
            : textSplit[i];

        if (type === 0 && lastSeg?.type === 'join' && curWord) {
          // Compatible joining
          lastSeg.transcript = curWordText;
          if (compareTransWithWord(curWordText, curWord.word)) {
            lastSeg.type = 'kept';
            lastSeg.meta = curWord;
            lastSeg.at = [curWord.index, wordsIdx];
            wordsFlatIdx++;
            wordsIdx++;
          }
        } else if (type === 0 && lastSeg?.type === 'join-remove' && curWord) {
          // Incompatible joining
          lastSeg.type = 'removed';
          lastSeg.transcript = curWord.word;
          lastSeg.meta = curWord;
          lastSeg.at = [curWord.index, wordsIdx];
          segments.push({
            type: 'added',
            transcript: curWordText,
            at: [curWord.index, wordsIdx],
          });
          wordsFlatIdx++;
          wordsIdx++;
        } else if (type === 0) {
          // Initial step
          if (compareTransWithWord(curWordText, curWord?.word)) {
            // Match word
            segments.push({
              type: 'kept',
              transcript: curWordText,
              meta: curWord,
              at: [curWord.index, wordsIdx],
            });
            wordsFlatIdx++;
            wordsIdx++;
          } else if (checkStrIncludeLeftToRight(curWord?.word, curWordText)) {
            // Match substring
            segments.push({
              type: 'join',
              transcript: curWordText,
              at: [curWord.index, wordsIdx],
            });
          } else {
            // No match (unknown)
            segments.push({
              type: 'added',
              transcript: curWordText,
              at: curWord?.empty ? [curWord.index, wordsIdx] : lastSeg?.at,
            });
            if (curWord?.empty) {
              wordsFlatIdx++;
              wordsIdx++;
            }
          }
        } else if (type === -1 && lastSeg?.type === 'join-remove' && curWord) {
          // Compatible joining
          lastSeg.transcript = curWordText;
          if (compareTransWithWord(curWordText, curWord.word)) {
            lastSeg.type = 'removed';
            lastSeg.transcript = curWordText;
            lastSeg.meta = curWord;
            lastSeg.at = [curWord.index, wordsIdx];
            wordsFlatIdx++;
            wordsIdx++;
          }
        } else if (type === -1 && lastSeg?.type === 'join' && curWord) {
          // Incompatible joining
          lastSeg.type = 'added';
          lastSeg.at = [curWord.index, wordsIdx];
          segments.push({
            type: 'removed',
            transcript: curWord.word,
            meta: curWord,
            at: [curWord.index, wordsIdx],
          });
          wordsFlatIdx++;
          wordsIdx++;
        } else if (type === -1) {
          // Initial step
          if (compareTransWithWord(curWordText, curWord?.word)) {
            // Match word
            segments.push({
              type: 'removed',
              transcript: curWordText,
              meta: curWord,
              at: [curWord.index, wordsIdx],
            });
            wordsFlatIdx++;
            wordsIdx++;
          } else if (checkStrIncludeLeftToRight(curWord?.word, curWordText)) {
            // Match substring
            segments.push({
              type: 'join-remove',
              transcript: curWordText,
              at: [curWord.index, wordsIdx],
            });
          } else {
            // No match (unknown)
            segments.push({
              type: 'ignored',
              transcript: curWordText,
              at: curWord?.empty ? [curWord.index, wordsIdx] : lastSeg?.at,
            });
            if (curWord?.empty) {
              wordsFlatIdx++;
              wordsIdx++;
            }
          }
        } else if (type === 1 && lastSeg?.type === 'join-remove' && curWord) {
          // Intersecting insertion when removing
          lastSeg.type = 'removed';
          lastSeg.transcript = curWord.word;
          lastSeg.meta = curWord;
          lastSeg.at = [curWord.index, wordsIdx];
          segments.push({
            type: 'added',
            transcript: curWordText,
            at: [curWord.index, wordsIdx],
          });
          wordsFlatIdx++;
          wordsIdx++;
        } else if (type === 1 && lastSeg?.type === 'join' && curWord) {
          // Intersecting insertion when keeping
          lastSeg.type = 'added';
          lastSeg.transcript += curWordText;
          segments.push({
            type: 'removed',
            transcript: curWord.word,
            meta: curWord,
            at: [curWord.index, wordsIdx],
          });
          wordsFlatIdx++;
          wordsIdx++;
        } else if (type === 1) {
          // Trivial insertion
          segments.push({
            type: 'added',
            transcript: curWordText,
            at: curWord?.empty ? [curWord.index, wordsIdx] : lastSeg?.at,
          });
          if (curWord?.empty) {
            wordsFlatIdx++;
            wordsIdx++;
          }
        } else {
          // Unhandled case (should not happen)
          const msg = 'BUG: Unhandled case in transcript diff';
          message.error(msg);
          console.error(msg);
        }
      }
    });
  });

  if (segments.length === 0) return returnSegments ? [] : sentences;

  if (returnSegments) return segments;
  return segmentsToEditedSegments(sentences, segments).filter((sentence) => sentence.transcript);
}

export function changeTransToNewSpeaker(sentences, sentenceOffset, changedValue, speaker) {
  const segments = diffToSegments(sentences, [sentenceOffset], [changedValue], true);

  if (!segments || segments.length === 0) return sentences;

  const keptSegments = segments.splice(
    0,
    segments.findIndex((s) => s.type === 'removed' || s.type === 'ignored')
  );
  const replacedToIndex = segments.findIndex((s) => s.type !== 'removed' && s.type !== 'ignored');
  const replacedSegments = segments.splice(0, replacedToIndex === -1 ? segments.length : replacedToIndex);

  // 1st phase
  const cpLeftSegments = JSON.parse(JSON.stringify(segments));
  const firstPhaseSegments = [
    ...keptSegments,
    ...replacedSegments,
    ...cpLeftSegments.filter((s) => s.type === 'kept').map((s) => ({ ...s, type: 'removed' })),
  ];
  // console.log("======1st phase:\n", firstPhaseSegments);

  let edited = segmentsToEditedSegments(sentences, firstPhaseSegments);

  // 2nd phase
  const segmentsToNewSentences = (seg, newSpeaker = false) => {
    if (seg.length === 0) return [];
    let idx = 0;
    let baseAt = seg[idx].at ? seg[idx].at[0] : null;
    while (_.isNil(baseAt)) {
      idx++;
      baseAt = seg[idx].at ? seg[idx].at[0] : null;
    }

    return seg.reduce((acc, s) => {
      if (!s.at || !s.transcript) return acc;
      if (acc[s.at[0] - baseAt]) {
        const curAcc = acc[s.at[0] - baseAt];
        acc[s.at[0] - baseAt] = {
          ...curAcc,
          words: [...curAcc.words, ...([s.meta] || [])],
          transcript: `${curAcc.transcript}${curAcc.transcript ? JOIN_CHAR : ''}${s.transcript}`,
          start: s.meta?.start < curAcc.start ? s.meta?.start : curAcc.start,
        };
      } else {
        acc.push({
          words: [s.meta],
          transcript: s.transcript,
          start: s.meta?.start || Number.MAX_SAFE_INTEGER,
          speaker: newSpeaker ? speaker : sentences[s.at[0]].speaker,
        });
      }

      return acc;
    }, []);
  };

  const newReplacedSentences = segmentsToNewSentences(replacedSegments, true);

  // 3rd phase
  const newLeftoverSentences = segmentsToNewSentences(segments);

  // 4th phase
  // let temp;
  const newReplacePosition = replacedSegments[0].at[0] + 1;
  // const firstReplacedWord = newReplacedSentences[0].words[0].word;
  // const lastReplacedWord = (temp =
  //   newReplacedSentences[newReplacedSentences.length - 1].words)[
  //   temp.length - 1
  // ].word;
  // const firstLeftoverTranscriptWord =
  //   newLeftoverSentences[0].transcript.split(JOIN_CHAR)[0];

  // let idx = newReplacePosition - 1;
  // while (newReplacedSentences[0].start === Number.MAX_SAFE_INTEGER) {
  //   const words = edited[idx].words;
  //   if (!words || words.length === 0) {
  //     idx--;
  //     continue;
  //   }
  //   let found = false;
  //   words
  //     .slice()
  //     .reverse()
  //     .forEach((w) => {
  //       if (w?.start < newReplacedSentences[0].start) {
  //         newReplacedSentences[0].start = w.start;
  //         found = true;
  //         return;
  //       }
  //     });
  //   if (found) break;
  //   idx--;
  // }

  // idx = newReplacedSentences.length;
  // while (newReplacedSentences[0].start === Number.MAX_SAFE_INTEGER) {
  //   const words = newReplacedSentences[idx].words;
  //   if (!words || words.length === 0) {
  //     idx--;
  //     continue;
  //   }
  //   let found = false;
  //   words
  //     .slice()
  //     .reverse()
  //     .forEach((w) => {
  //       if (w?.start < newLeftoverSentences[0].start) {
  //         newLeftoverSentences[0].start = w.start;
  //         found = true;
  //         return;
  //       }
  //     });
  //   if (found) break;
  //   idx--;
  // }

  // let slicedToIdx = 0;
  // while (
  //   firstLeftoverTranscriptWord.startsWith(
  //     firstReplacedWord.slice(0, slicedToIdx)
  //   )
  // ) {
  //   slicedToIdx++;
  //   if (slicedToIdx > firstReplacedWord.length) break;
  // }
  // slicedToIdx--;

  // if (slicedToIdx) {
  //   newLeftoverSentences[0].transcript =
  //     newLeftoverSentences[0].transcript.slice(slicedToIdx);
  //   edited[newReplacePosition - 1].transcript +=
  //     JOIN_CHAR + firstReplacedWord.slice(0, slicedToIdx);
  //   newReplacedSentences[0].transcript =
  //     newReplacedSentences[0].transcript.slice(slicedToIdx);
  //   newReplacedSentences[0].words = newReplacedSentences[0].words.slice(1);
  // }

  // let slicedFromIdx = lastReplacedWord.length;
  // while (
  //   firstLeftoverTranscriptWord.endsWith(lastReplacedWord.slice(slicedFromIdx))
  // ) {
  //   slicedFromIdx--;
  //   if (slicedFromIdx < 0) break;
  // }
  // slicedFromIdx++;

  // if (slicedFromIdx - lastReplacedWord.length) {
  //   newReplacedSentences[newReplacedSentences.length - 1].transcript =
  //     newReplacedSentences[newReplacedSentences.length - 1].transcript.slice(
  //       0,
  //       -slicedFromIdx - 1
  //     );
  //   newReplacedSentences[newReplacedSentences.length - 1].words =
  //     newReplacedSentences[newReplacedSentences.length - 1].words.slice(0, -1);
  // }

  edited.splice(newReplacePosition, 0, ...newReplacedSentences);
  edited.splice(newReplacePosition + newReplacedSentences.length, 0, ...newLeftoverSentences);

  return edited.filter((sentence) => sentence.transcript);
}

export function replaceSentencesWithDict(sentences, dicts) {
  if (sentences.length === 0) return [];
  const finalParagraphs = [];
  let paragraph = [];
  let oldItem = null;
  const filler = { speaker: -1 };
  const paragraphOffsets = [];
  [...sentences, filler].forEach((items, index) => {
    if (items.transcript === '') {
      return;
    } else {
      const localParagraph = paragraph;
      const paragraphItems = sentences.filter((_, i) => localParagraph.includes(i));
      const localTranscripts = paragraphItems.map((i) => i.transcript);
      const localOldItem = oldItem;
      oldItem = items;
      paragraph = [index];
      if (localTranscripts.length === 0 || (!localOldItem.speaker && localOldItem.speaker !== 0)) return null;
      paragraphOffsets.push([localParagraph[0], localParagraph[localParagraph.length - 1]]);
      finalParagraphs.push(localTranscripts);
    }
  });

  const changedValues = [];
  finalParagraphs.forEach((p) => {
    let text = p.join(JOIN_CHAR);
    if (!dicts) {
      changedValues.push(text);
      return;
    }
    dicts.forEach((dict) => {
      if (IS_NTTDATA) {
        const regex = new RegExp(dict.word1, 'igm');
        text = text.replace(regex, dict.word3);
      } else if (IS_SAFARI) {
        // Costly operation
        text = XRegExp.replaceLb(
          text,
          `(?<!${VNMESE_CHAR}+)`,
          new RegExp(`${dict.word1}(?!${VNMESE_CHAR}+)`),
          dict.word3
        );
      } else {
        const regex = new RegExp(`(?<!${VNMESE_CHAR}+)${dict.word1}(?!${VNMESE_CHAR}+)`, 'igm');
        text = text.replace(regex, dict.word3);
      }
    });
    changedValues.push(text);
  });

  return diffToSegments(sentences, paragraphOffsets, changedValues);
}

import React, { useState, useEffect, useRef } from "react";

import {
  Layout,
  Table,
  Row,
  Col,
  Card,
  Upload,
  Input,
  Button,
  Form,
  Tag,
  Modal,
  Dropdown,
  Menu,
  Space,
  message,
} from "antd";
import {
  DownloadOutlined,
  SyncOutlined,
  SearchOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  DownOutlined,
} from "@ant-design/icons";

import moment from "moment";
import { db, storage } from "utils/firebase";
import useAuth from "hooks/useAuth";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import * as uuid from "uuid";

import "./styles.less";
import axios from "axios";
import { getVideo } from "apis/video";
import { MeiliSearch } from "meilisearch";
import Search from "antd/lib/input/Search";
import { IS_FUNIX, IS_LONGCHAU } from "utils/constants";

import LoadKeyword from "./LoadKeyword";
import _ from "lodash";

const { confirm } = Modal;

const { Dragger } = Upload;

const {
  REACT_APP_TENANT_ID,
  REACT_APP_FUNIX_API,
  REACT_APP_MEILI_HOST,
  REACT_APP_MEILI_API_KEY,
} = process.env;

const searchClient = IS_FUNIX
  ? new MeiliSearch({
      host: REACT_APP_MEILI_HOST,
      apiKey: REACT_APP_MEILI_API_KEY,
    })
  : null;

const optionsData = [
  {
    label: "onePerson",
    data: {
      number_speaker: 1,
      number_channel: 1,
      pipeline: "old",
    },
  },
  {
    label: "twoPeopleMonoChannelWithoutOverlap",
    data: {
      number_speaker: 2,
      number_channel: 1,
      pipeline: "old",
    },
  },
  {
    label: "twoPeopleMonoChannel",
    data: {
      number_speaker: 2,
      number_channel: 1,
      pipeline: "new",
    },
  },
  {
    label: "twoPeopleStereoChannels",
    data: {
      number_speaker: 2,
      number_channel: 2,
      pipeline: "old",
    },
  },
];

const KeywordSearch = ({ baseUrl = "/user/keyword-search" }) => {
  const { t } = useTranslation();
  const language = getLanguages(t);
  const loadAllKeywordCallbacks = useRef({});

  const { user, token } = useAuth();

  const [files, setFiles] = useState([]);
  const [filterFiles, setFilterFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const [submit, setSubmit] = useState(false);

  const [option, setOption] = useState(2);

  useEffect(() => {
    if (user) {
      setLoading(true);
      let query = db.collection("files");

      if (!IS_FUNIX && !IS_LONGCHAU)
        query = query.where("user", "==", user?.uid);
      else query = query.where("tenantId", "==", REACT_APP_TENANT_ID);

      query.orderBy("createdAt", "desc").onSnapshot((snapshot) => {
        setLoading(true);
        const files = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data?.createdAt?.toDate(),
            length: convertToDuration(data?.length),
            lastEdit: data?.lastEdit?.toDate() || data?.createdAt?.toDate(),
          };
        });

        setFiles(files);
        setFilterFiles(files);
        setLoading(false);
      });

      db.doc(`users/${user.uid}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            return;
          }
        })
        .catch((err) => {});
    }
  }, [user]);
  // useEffect(() => {
  //   const sendData = async () => {
  //     if (baseAudioUrl?.length > 0) {
  //       setTranscript(language.predicting);
  //       const blob = await fetch(baseAudioUrl).then((r) => r.blob());
  //       const reader = new FileReader();
  //       reader.readAsDataURL(blob);
  //       reader.onloadend = () => {
  //         const base64data = reader.result;
  //         setBase64data(base64data);

  //         axios
  //           .post(`${process.env.REACT_APP_MEETING_API_URL}/transcript`, {
  //             audio: base64data,
  //             lang: 'vi', //
  //             dict: dicts.map(item => {
  //               let word2 = (item?.word2 === undefined || item?.word2 === '')  ? '*' : item?.word2;
  //               let word4 = (item?.word4 === undefined || item?.word4 === '')  ? '*' : item?.word4;

  //               let word = item.word1 + ", " + word2 + ", " + item.word3 + ", " +  word4 + ", " + item.word5
  //               return word
  //             })
  //           })
  //           .then((res) => {
  //             setTranscript(res.data.transcript);
  //             setTranscriptEdit(res.data.transcript);
  //           });
  //       };
  //     }
  //   };
  //   sendData();
  // }, [baseAudioUrl]);

  // const startRecord = () => {
  //   alert('This feature is developing!')
  //   return;
  //   if (startRec) {
  //     clearTimeout(timeoutIdRec);
  //     clearInterval(timeIntervalIdRec);
  //     setStartRec(false);
  //     stopRecording();
  //   } else {
  //     //log.debug("[App] start record " + isRecording);
  //     setStartRec(true);
  //     startRecording();
  //     let count = 20;
  //     setMsgRec(`${count} ${language.seconds} .`);
  //     const intevalRec = setInterval(() => {
  //       count -= 1;
  //       if (count % 3 === 0) {
  //         setMsgRec(`${count} ${language.seconds} .`);
  //       } else if (count % 3 === 1) {
  //         setMsgRec(`${count} ${language.seconds} . .`);
  //       } else {
  //         setMsgRec(`${count} ${language.seconds} . . .`);
  //       }
  //     }, 1000);
  //     setTimeIntervalIdRec(intevalRec);
  //     setTimeoutIdRec(
  //       setTimeout(() => {
  //         //log.debug("[App] auto stop");
  //         setStartRec(false);
  //         stopRecording();
  //         clearInterval(intevalRec);
  //       }, 20000)
  //     );
  //   }
  // };

  const convertToDuration = (value) => {
    if (isNaN(value) || !isFinite(value)) return "00:00:00";

    // calculate hours
    const hours = Math.floor(value / 3600);
    value -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(value / 60) % 60;
    value -= minutes * 60;

    // calculate minutes
    const seconds = Math.round(value);

    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const onSearch = async (e) => {
    if (!e) return;

    const value = e.target ? e.target.value : e;

    const index = searchClient.index("transcripts");
    const result = await index.search(value);
    setFilterFiles((prev) =>
      files.filter((item) =>
        result.hits.some((hit) => item.name.includes(hit.id))
      )
    );
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {language.search}
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text, record, index) => {
      if (record.status === "TRANSCRIBED") {
        return (
          <a
            rel="noopener noreferrer"
            target={"_blank"}
            href={baseUrl + "/" + record.id}
          >
            {record.name}
          </a>
        );
      } else {
        return <div>{record.name}</div>;
      }
    },
  });

  const columns = [
    {
      title: language.name,
      dataIndex: "name",
      width: "15%",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: (
        <div>
          Keywords
          <Button
            type="link"
            style={{ display: "inline-block" }}
            onClick={() => {
              _.values(loadAllKeywordCallbacks.current).forEach((callback) => {
                callback();
              });
            }}
          >
            Load all
          </Button>
        </div>
      ),
      align: "center",
      width: "20%",
      key: ({ id }) => id,
      render: ({ id }) => {
        return (
          <LoadKeyword
            key={id}
            loadAllKeywordCallbacks={loadAllKeywordCallbacks}
            id={id}
          />
        );
      },
    },
    {
      title: language.length,
      align: "center",
      dataIndex: "length",
      width: "3%",
      sorter: {
        compare: (a, b) => a.length.localeCompare(b.length),
      },
      render: (text, record, index) => {
        if (record.status === "TRANSCRIBED") return <div>{record.length}</div>;
      },
    },
    {
      title: language.status,
      align: "center",
      width: "3%",
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
      render: (record) => {
        switch (record.status) {
          case "TRANSCRIBING":
            return (
              <Tag icon={<SyncOutlined spin />} color="#108ee9">
                {language.transcribing}
              </Tag>
            );
          case "TRANSCRIBED":
            return (
              <a target={"_blank"} href={baseUrl + "/" + record.id}>
                <Tag color="#87d068">{language.ready}</Tag>
              </a>
            );
          case "ERROR":
            return <Tag color="#ff0000">{language.error}</Tag>;
          case "UPLOADED":
            return <Tag color="#ff9300">{language.uploaded}</Tag>;
          default:
            return null;
        }
      },
    },
    {
      title: language.action,
      align: "center",
      width: "2%",
      render: (record) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {record.status === "TRANSCRIBED" && (
              <a target={"_blank"} href={baseUrl + "/" + record.id}>
                <Button type="link" icon={<EyeOutlined />}>
                  {language.btnView}
                </Button>
              </a>
            )}

            <Button
              icon={<DeleteOutlined />}
              type="link"
              danger
              onClickCapture={() => {
                confirm({
                  title: language.doYouWantToDeleteTheseItems,
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    if (user) {
                      setLoading(true);
                      if (IS_FUNIX && record.source === "funix") {
                        axios
                          .delete(REACT_APP_FUNIX_API + "/file", {
                            data: {
                              fileName: record.uuid,
                            },
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          .then((res) => {
                            if (res.status === 200) {
                              message.success(language.deleteSuccess);
                              setLoading(false);
                            } else {
                              message.error(language.deleteFail);
                              setLoading(false);
                            }
                          })
                          .catch((err) => {
                            message.error(language.deleteFail);
                            setLoading(false);
                          });
                      } else {
                        if (IS_FUNIX) {
                          searchClient
                            .index("transcripts")
                            .deleteDocument(
                              record.name.split(".").slice(0, -1).join(".")
                            );
                        }

                        // Delete Items Action
                        storage
                          .refFromURL(record.url)
                          .delete()
                          .catch((err) => {
                            message.error(language.deleteFail);
                            setLoading(false);
                          });

                        db.collection("audioTasks").doc(record.taskId).update({
                          status: "DELETED",
                        });

                        db.collection("files")
                          .doc(record.id)
                          .delete()
                          .catch((err) => {
                            message.error(language.deleteFail);
                            setLoading(false);
                          });

                        // Delete subcollections
                        db.collection("files")
                          .doc(record.id)
                          .collection("sentences")
                          .get()
                          .then((snapshot) => {
                            snapshot.forEach((doc) => {
                              doc.ref.delete();
                            });
                          });

                        db.collection("files")
                          .doc(record.id)
                          .collection("sentencesEdited")
                          .get()
                          .then((snapshot) => {
                            snapshot.forEach((doc) => {
                              doc.ref.delete();
                            });
                          });

                        message.success(language.deleteSuccess);
                        setLoading(false);
                      }
                    } else {
                      message.error(language.deleteFail);
                      setLoading(false);
                    }
                  },
                  onCancel() {
                    console.log("Cancel");
                  },
                });
              }}
            >
              {language.btnDelete}
            </Button>
          </div>
        );
      },
    },
  ];

  const onSelectFile = async (e, index = null) => {
    const file = e.file;
    if (file) {
      const fileUUID = uuid.v4();
      const createdAt = moment();

      const fileExtension = file.name.split(".").pop();
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`${fileUUID}.${fileExtension}`);

      const uploadTask = fileRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          if (e.onProgress) e.onProgress({ percent: percent });
          else setSubmit(true);
        },
        (err) => {
          if (e.onError) e.onError(err);
        },
        () => {
          // download url
          uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
            const task = await db.collection("audioTasks").add({
              createdAt: createdAt.toDate(),
              url,
              status: "UPLOADED",
              fileName: file.name,
              tenantId: REACT_APP_TENANT_ID,
              ...optionsData[option].data,
            });

            let payload = {
              uuid: fileUUID,
              name: file.name.split(".").slice(0, -1).join("."),
              user: user?.uid,
              length: 0,
              createdAt: createdAt.toDate(),
              lastEdit: createdAt.toDate(),
              taskId: task.id,
              url: url,
              status: "UPLOADED",
              tenantId: REACT_APP_TENANT_ID,
              source: "saas",
            };

            if (IS_FUNIX)
              searchClient.index("transcripts").updateDocuments([
                {
                  id: payload.name.split(".").slice(0, -1).join("."),
                  transcript: [],
                },
              ]);

            db.collection("files").add(payload);
          });
          if (e.onSuccess) e.onSuccess();
          else setSubmit(false);
        }
      );
    }
  };

  const props = {
    name: "file",
    customRequest: onSelectFile,
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: false,
    },
    multiple: true,
  };

  const submitLink = async (e) => {
    setSubmit(true);
    if (e.links) {
      e.links.forEach(async (item, index) => {
        const url = new URL(item.link);
        switch (url.hostname) {
          case "www.youtube.com":
          case "youtube.com":
          case "m.youtube.com":
            const result = await getVideo(token, { url: url.href });
            if (result.video) {
              alert(
                "Youtube Video uploaded successfully, please wait several minutes for processing"
              );
            } else {
              alert("Youtube processing failed");
            }
            break;
          default:
            axios({
              method: "get",
              url: item.link,
              responseType: "blob",
            })
              .then(function (response) {
                onSelectFile(
                  { file: new File([response.data], "download-file.mp4") },
                  index
                );
              })
              .catch((err) => {
                alert("Download file unsuccessful");
                console.log(err);
                setSubmit(false);
              });
        }
      });
    }
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a
          onClick={(e) => {
            e.preventDefault();
            setOption(0);
          }}
          href="#"
        >
          {t(optionsData[0].label)}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={(e) => {
            e.preventDefault();
            setOption(1);
          }}
          href="#"
        >
          {t(optionsData[1].label)}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={(e) => {
            e.preventDefault();
            setOption(2);
          }}
          href="#"
        >
          {t(optionsData[2].label)}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={(e) => {
            e.preventDefault();
            setOption(3);
          }}
          href="#"
        >
          {t(optionsData[3].label)}
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }}>
        <Card style={{ marginBottom: 18 }}>
          {`${language.fileTranscriptSettings} `}
          <Dropdown overlay={menu}>
            <Space>
              <span style={{ color: "#4a90e2", fontWeight: "bold" }}>
                {t(optionsData[option].label)}
              </span>
              <DownOutlined />
            </Space>
          </Dropdown>
          <Row gutter={24} style={{ marginTop: 24 }}>
            <Col span={12}>
              <Card style={{ height: `100%` }}>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <DownloadOutlined
                      style={{ fontSize: 36, color: "inherit" }}
                    />
                  </p>
                  <p className="ant-upload-text">
                    {language.selectDragDropVideo}
                  </p>
                </Dragger>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ height: `100%`, justifyContent: "center" }}>
                <p style={{ fontSize: 16 }}>{language.pasteVideo}</p>
                {/* <Form onFinish={submitLink}>
                      <Form.Item name="link">
                        <Input style={{ width: 'calc(100% - 120px)' }} defaultValue="http://link.com/video.mp4" />
                      </Form.Item>
                      <Button type="primary" htmlType="submit">
                        {language.btnSubmit}
                      </Button>
                    </Form> */}
                <Form
                  onFinish={submitLink}
                  initialValues={{
                    links: [
                      {
                        link: "http://link.com/video.mp4",
                      },
                    ],
                  }}
                >
                  <Form.List name="links">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <>
                            <Row gutter={24}>
                              <Col lg={{ span: 20 }} xs={{ span: 20 }}>
                                <Form.Item
                                  label={"Link"}
                                  {...restField}
                                  name={[name, "link"]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col lg={{ span: 4 }} xs={{ span: 4 }}>
                                {fields.length !== 1 && (
                                  <>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />{" "}
                                    {language.btnDelete}
                                  </>
                                )}
                              </Col>
                            </Row>
                          </>
                        ))}
                        <Button type="primary" htmlType="submit">
                          {language.btnSubmit}
                        </Button>
                        <Button
                          type="link"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          {language.btnAdd}
                        </Button>
                        {submit && (
                          <p style={{ marginTop: "4px" }}>
                            Downloading and Uploading to Server...
                          </p>
                        )}
                      </>
                    )}
                  </Form.List>
                </Form>
              </Card>
            </Col>
          </Row>
        </Card>

        {IS_FUNIX && (
          <div style={{ width: "100%" }}>
            <Search
              placeholder={language.search}
              onSearch={onSearch}
              onChange={onSearch}
              style={{ width: 300, marginBottom: 18, float: "right" }}
            />
          </div>
        )}

        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={filterFiles}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
          }}
        />
      </Layout>
    </Layout>
  );
};

export default KeywordSearch;
